




































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
	REGION_UPDATE,
	REGION_CREATE,
	REGION_REQUEST,
	REGION_LOADED,
} from "@/store/actions/main";
import { Region } from "@/models/Region";

@Component({})
export default class RegionsForm extends Vue {
	private form: any = {};
	private imageUrl: any = "";
	private loading: boolean = false;
	//Snackbar
	private messageText = "Не удалось выполнить";
	private messageColor = "red";
	private message = false;
	//Icon
	private imageLoaded: boolean = false;
	private preview: string = "";
	$refs!: {
		inp: any;
	};
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];

		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;
	}
	private initSelf() {
		console.log("INIT SELF upd");
		this.initStartOptions();
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private editItem() {
		var converted = new FormData();
		delete this.form.icon_path;
		Object.keys(this.form).forEach((key) => {
			console.log("----------------");
			console.log(key, this.form[key]);
			converted.set(key, this.form[key]);
		});
		var data = {
			id: +this.$route.params.id,
			data: converted,
		};
		this.$store
			.dispatch(REGION_UPDATE, data)
			.then(() => {
				this.messageText = "Регион " + this.form.name + " изменен.";
				this.messageColor = "green";
				this.tryToInitSelf();
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
			});
	}
	private createItem() {
		var converted = new FormData();
		Object.keys(this.form).forEach((key) => {
			console.log(key);
			converted.append(key, this.form[key]);
		});
		this.$store
			.dispatch(REGION_CREATE, converted)
			.then(() => {
				this.tryToInitSelf();
			})
			.then(() => {
				this.messageText = "Регион " + this.form.name + " добавлен.";
				this.messageColor = "green";
				this.tryToInitSelf();
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
			});
	}
	private toString(str) {
		return str as string;
	}
	private isUpdatePage() {
		return this.$route.meta.isUpdate;
	}
	private initStartOptions() {
		var item;
		if (localStorage.getItem("regionToEdit")) {
			console.log("update page");
			try {
				item = JSON.parse(this.toString(localStorage.getItem("regionToEdit")));
				console.log("item init: ", item);
			} catch (e) {
				localStorage.removeItem("regionToEdit");
			}
		} else {
			console.log("create page");
		}
		/*
		Object.keys(item).forEach((key) => {
			this.form[key] = item[key];
		});
		*/
		//console.log(this.props.isUpdatePage);
		if (this.isUpdatePage()) {
			this.form.name = item.name;
			//this.form.regionName = item.name;
			this.form.icon_path = this.getSrc(item.icon_path);
			this.form.title = item.title;
			//this.regionId = item.id;
			console.log("form: ", this.form);
		}
	}
	//Image
	private createImage(file) {
		const reader = new FileReader();

		reader.onload = (e) => {
			let ev = {} as any;
			ev = e;
			this.imageUrl = ev.target.result;
		};
		reader.readAsDataURL(file);
	}
	private onFileChange(file) {
		if (!file) {
			return;
		}
		this.createImage(file);
	}
	private clearImage() {
		// this.preview = "";
		// this.imageLoaded = false;
		delete this.form.icon_file;
		this.$forceUpdate();
	}
	private getSrc(address) {
		return process.env.VUE_APP_API_ENDPOINT + "/" + address;
	}
	//Hooks
	private created() {
		var item;
		if (localStorage.getItem("regionToEdit")) {
			item = JSON.parse(this.toString(localStorage.getItem("regionToEdit")));
			this.$router.currentRoute.meta.breadcrumb[2].text = ""+item.name;
		}
	}
	private mounted() {
		this.tryToInitSelf();
	}
	private destroyed() {
		localStorage.removeItem("regionToEdit");
	}
}
